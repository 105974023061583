/* eslint-disable */
import {FuseNavigationItem} from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.project',
                title: 'Project',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/dashboards/project'
            }
        ]
    },
    {
        id: 'payments',
        title: 'Payments',
        subtitle: 'Everything related to payments',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'payments.orders',
                title: 'Orders',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/payments/orders'
            },
            // {
            //     id   : 'payments.history',
            //     title: 'History',
            //     type : 'basic',
            //     icon : 'heroicons_outline:clipboard-document-check',
            //     link : '/payments/history'
            // },
            // {
            //     id   : 'payments.documents',
            //     title: 'Documents',
            //     type : 'basic',
            //     icon : 'heroicons_outline:clipboard-document-check',
            //     link : '/payments/documents'
            // }
        ]
    },

    {
        id: 'companies',
        title: 'Companies',
        subtitle: 'Management of all companies',
        type: 'group',
        children: [
            {
                id: 'companies.list',
                title: 'Companies',
                type: 'basic',
                icon: 'heroicons_outline:building-office-2',
                link: '/companies'
            }
        ]
    },

    {
        id: 'mails-app',
        title: 'Mails',
        type: 'group',
        children: [
            {
                id: 'mails-app.stats',
                title: 'Stats',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/mails/stats'
            },
            {
                id: 'mails-app.list',
                title: 'Mails Tracking ',
                type: 'basic',
                icon: 'heroicons_outline:envelope',
                link: '/mails',
                exactMatch: true,
            }
        ]
    },
    {
        id: 'front-app',
        title: 'Front App',
        subtitle: 'Management of all front app things',
        type: 'group',
        children: [
            {
                id: 'front-app.list',
                title: 'Front App Conversations Reviews',
                type: 'basic',
                icon: 'heroicons_outline:pencil-square',
                link: '/front-app'
            }
        ]
    },
    {
        id: 'resources',
        title: 'Resources',
        subtitle: 'Management of all website resources',
        type: 'group',
        icon: 'heroicons_outline:folder-open',
        children: [
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/users'
            },
            {
                id: 'subscriptions',
                title: 'Subscriptions',
                type: 'collapsable',
                icon: 'heroicons_outline:user-group',
                children: [
                    {
                        id: 'subscriptions.list',
                        title: 'List',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: '/subscriptions'
                    },
                    {
                        id: 'subscriptions.plans',
                        title: 'Plans',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: '/subscriptions/plans'
                    }
                ]
            },
            {
                id: 'offers',
                title: 'Offers',
                type: 'collapsable',
                icon: 'heroicons_outline:tag',
                children: [
                    {
                        id: 'offers.list',
                        title: 'List',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: '/offers',
                        exactMatch: true,

                    },
                    {
                        id: 'offers.categories',
                        title: 'Categories',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: '/offers/categories'
                    },
                ]
            },
            {
                id: 'services',
                title: 'Services',
                type: 'collapsable',
                icon: 'heroicons_outline:tag',
                children: [
                    {
                        id: 'services.list',
                        title: 'List',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: '/services',
                        exactMatch: true,
                    },
                    {
                        id: 'services.categories',
                        title: 'Categories',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: '/services/categories'
                    },
                ]
            },
            // {
            //     id: 'events',
            //     title: 'Events',
            //     type: 'basic',
            //     icon: 'heroicons_outline:tag',
            //     link: '/events',
            // },
            {
                id: 'suggestions',
                title: 'Suggestions',
                type: 'basic',
                icon: 'heroicons_outline:tag',
                link: '/suggestions',
            },
            {
                id: 'news',
                title: 'News',
                type: 'basic',
                icon: 'heroicons_outline:tag',
                link: '/news',
            },
            // {
            //     id: 'compliments',
            //     title: 'Compliments',
            //     type: 'basic',
            //     icon: 'heroicons_outline:tag',
            //     link: '/compliments',
            // },
            // {
            //     id: 'content',
            //     title: 'Content',
            //     type: 'basic',
            //     icon: 'heroicons_outline:tag',
            //     link: '/contents',
            // }
        ],
    }

];
export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
